/* global BigInt */

import web3js from "web3";
import carrABI from './abi/carr.json';
import detectEthereumProvider from '@metamask/detect-provider';

const web3 = new web3js(window.ethereum);
try { window.ethereum.enable(); } catch { console.log("Install metamask"); }

const carrContract = new web3.eth.Contract(carrABI, process.env.REACT_APP_CARR_ADDRESS);
const expectedBlockTime = 1000;

const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

const detectProvider = async () => {
  const provider = await detectEthereumProvider();
  
  return new Promise(async (resolve, reject) => {
    try{
      if(provider.selectedAddress === null) {
        alert("Sign into Metamask");
        return reject("Sign into Metamask");
      } 
      resolve(provider);
    } catch (err) {
      alert('Please install MetaMask or login');
      console.log(err);
    }
  });
}

const getAccounts = (callback) => {
  web3.eth.getAccounts((error, result) => {
    if (error) {
      console.log(error);
    } else {
      callback(result);
    }
  });
}

async function getCurrentAccount() {
  const { ethereum } = window;
  const accounts = await ethereum.request({ method: 'eth_accounts' });
  return accounts[0];
}

const getStakedBalance = async () => {
  return new Promise(async (resolve, reject) => {
    const { ethereum } = window;

    if (ethereum) {
      const accounts = await ethereum.request({ method: 'eth_accounts' });
      if (accounts.length < 1) {
        return reject("No Accounts Found.");
      }
      const balance = await carrContract.methods.balanceOfStaked(accounts[0]).call();
      resolve(balance / 10 ** 18);
    } else {
      reject();
    }
  });
}

const getRewardsBalance = async () => {
  return new Promise(async (resolve, reject) => {
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: 'eth_accounts' });

    if (accounts.length < 1) {
      return reject("No Accounts Found.");
    }
    const balance = await carrContract.methods.rewardsOf(accounts[0]).call();
    resolve(balance / 10 ** 18);
  });
}

const getBalance = async () => {
  return new Promise(async (resolve, reject) => {
    const { ethereum } = window;
    const accounts = await ethereum.request({ method: 'eth_accounts' });
    
    if (accounts.length < 1) {
      return reject("No Accounts Found.");
    }
    const balance = await carrContract.methods.balanceOf(accounts[0]).call();
    resolve(balance / 10 ** 18);
  });
};

const getAddr = async () => {
  const { ethereum } = window;
  const accounts = await ethereum.request({ method: 'eth_accounts' });
  return accounts[0];
}

const transfer = async (amount) => {
  return new Promise(async (res) => {
    let amt = BigInt(amount) * BigInt(10**18);
    const account = await getCurrentAccount();
    // let qtyApproved = BigInt(await carrContract.methods.allowance(account, process.env.REACT_APP_CARR_ADDRESS).call({ from: account }));
    // console.log("Allowance: " + qtyApproved);
    console.log("amt: " + amt);

    // if (qtyApproved < amt) {
    //   await carrContract.methods.approve(process.env.REACT_APP_CARR_ADDRESS, amt.toString()).send({ from: account }).catch(error => {
    //     console.error(error.message);
    //     res(false);
    //   })

    //   console.log("New allowance");
    //   await carrContract.methods.allowance(account, process.env.REACT_APP_CARR_ADDRESS).call({ from: account });
    //   await carrContract.methods.stake(amt).send({ from: account }, async function(error, transactonHash) {      
    //     console.log("Submitted transaction with hash: ", transactonHash);
    //     let transactionReceipt = null;

    //     while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
    //       transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
    //       await sleep(expectedBlockTime);
    //     }
    //     console.log("Got the transaction receipt: ", transactionReceipt);
    //   }).catch(error => {
    //     console.error(error.message);
    //     res(false);
    //   });
    //   res(true);
    // } else {
      await carrContract.methods.stake(amt).send({ from: account }, async function(error, transactonHash) {      
        console.log("Submitted transaction with hash: ", transactonHash);     
        let transactionReceipt = null;

        while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
          transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
          await sleep(expectedBlockTime);
        }
        console.log("Got the transaction receipt: ", transactionReceipt)
      }).catch(error => {
        console.error(error.message);
        res(false);
      });
      res(true);
    // }
  })
}

const withdrawAll = async () => {
  return new Promise(async (res) => {
    const account = await getCurrentAccount();
    carrContract.methods.withdrawAll().send({ from: account }, async function(error, transactonHash) {
      console.log("Submitted transaction with hash: ", transactonHash);
      let transactionReceipt = null;

      while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
        transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
        await sleep(expectedBlockTime);
      }
      console.log("Got the transaction receipt: ", transactionReceipt);
      res(true);
    }).catch(error => {
      console.error(error.message);
      res(false);
    });    
  })
}

const withdraw = async (amount) => {
  const qty = BigInt(amount) * BigInt(10**18);
  const account = await getCurrentAccount();
  const withdraw = carrContract.methods.withdraw(qty.toString()).send({ from: account }, async function(error, transactonHash) {
    console.log("Submitted transaction with hash: ", transactonHash);
    let transactionReceipt = null;

    while (transactionReceipt == null) { // Waiting expectedBlockTime until the transaction is mined
      transactionReceipt = await web3.eth.getTransactionReceipt(transactonHash);
      await sleep(expectedBlockTime);
    }
    console.log("Got the transaction receipt: ", transactionReceipt)
  });
  console.log("Withdraw: " + amount);
  return withdraw;
}

const ethEnabled = async () => {
  if (window.ethereum) {
    window.ethereum.enable();
    await window.ethereum.request({ method: 'eth_requestAccounts' });
    window.web3 = new web3js(window.ethereum);
    return true;
  }
  return false;
}

async function loadWeb3() {
    if (window.ethereum) {
      console.log("loadWbe3");
      window.web3 = new web3js(window.ethereum);
      window.ethereum.enable();
    }
}

function decimalAdjust(type, value, exp) {
  // If the exp is undefined or zero...
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

const floor10 = (value, exp) => decimalAdjust('floor', value, exp);

export {
  floor10,
  loadWeb3,
  ethEnabled,
  withdraw,
  withdrawAll,
  transfer,
  getAddr,
  getBalance,
  getRewardsBalance,
  getStakedBalance,
  getAccounts,
  detectProvider
};
