import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
import ReactDOM from 'react-dom';
import WalletComponent from './WalletComponent';
import StakingComponent from './StakingComponent';
import StakeTkn from '../assets/img/stakeTkn.svg';
import logo from '../assets/img/logo.svg';
import mMask from '../assets/img/iconMetamask.png';
import '../App.css';
import jQuery from "jquery";
import $ from "jquery";
import { getStakedBalance, getRewardsBalance, getAddr, getBalance, withdrawAll, floor10 } from '../operations.js';

const WithdrawComponent = () => {
  const [state, setState] = useState('start');
  const formatter = new Intl.NumberFormat('en-US', { style: 'decimal' });

  useEffect(() => {
    const promise = [getStakedBalance(), getRewardsBalance(), getAddr(), getBalance()];

    jQuery(function () {
      return Promise.all(promise).then(function (res) {
        $(".earned-balance-label").text(floor10(res[1], -6));
        $(".estimated-label").text(floor10(res[1] + res[0],-6));
        $(".staked-balance-label").text(floor10(res[0],-6));

        $(".total-btn").text(formatter.format(floor10(res[3]), -6));
        $(".addr-label").text(res[2]);
        $(".balance-label").text(formatter.format(floor10(res[3], -6)));
        return true;
      }).catch((error) => {
        console.error(error + ', please install MetaMask!');
      });
    })
  });

  function toStakePage() {
    ReactDOM.render(<StakingComponent/>, document.getElementById('root'));
  }

  function toWithdrawPage() {
    jQuery(function () {
      const promise = [getStakedBalance(), getRewardsBalance()];

      return Promise.all(promise).then(function (res) {
        $(".balance-label").text(floor10(res[0] + res[1], -6));
        return true;
      })
    });

    setState("withdrawA");
    jQuery(function () {
      $('.accrue-notice').text("Percentage earned is accrued per second. Withdraw Estimate will be updated when transaction is confirmed.");
    })
  }

  function handleWithdraw() {
    $('.confirm-btn').text('Waiting...');
    $('.confirm-btn').prop("disabled", true);
    $(document.body).css("background-color", "#D3D3D3");
    withdrawAll().then((res) => {
      if (res) {
        $(document.body).css({background:"linear-gradient(to bottom, rgba(242,149,30,0), rgba(255,174,0,0.2))"});
        home();
      } else {
        $('.confirm-btn').prop("disabled", false);
        $('.confirm-btn').text('Confirm Withdrawal');
        $(document.body).css({ background: "linear-gradient(to bottom, rgba(242,149,30,0), rgba(255,174,0,0.2))" });
      }
    })
  }

  function toWalletPage() {
    ReactDOM.render(<WalletComponent />, document.getElementById('root'));
  }

  function home() {
    ReactDOM.render(<StakingComponent/>, document.getElementById('root'));
  }

  return <>
    <Container>
    <Row className="justify-content-md-center mt-5">
    <Col xs="3" md="2">
      <Image className="justify-content-center main-logo" onClick={home} src={logo} />
    </Col>
    <Col xs="1" md="1">
      {/* <p>Ropsten</p> */}
    </Col>
    <Col xs="4" md="1">
      <span className="rounded-pill btn btn-sm total-btn active" role="button" aria-pressed="true"></span>
    </Col>
    <Col xs="4" md="2">
      <Row>
        <Col xs="7">
          <p onClick={toWalletPage} className="addr-label"></p>
        </Col>
        <Col xs="4">
          <Image className="mMask" src={mMask} onClick={toWalletPage} />
        </Col>
        <Col xs="1">
        </Col>
      </Row>
    </Col>
  </Row>
    </Container>
    <Container className="main">
      <Row>
        <Col xs md="3">
        </Col>
        <Col md="6" className="play">
          <Stack gap={1} className="col-md-5 mx-auto pt-5">
            <Image className="justify-content-center center-icon mx-auto" src={StakeTkn} />
            {state === 'start' && (<div className="pt-4 balance-font mx-auto h3">Token Stake: <p className="staked-balance-label text-break h5">loading...</p></div>)}
            {state === 'withdrawA' && (<div className="pt-4 balance-font mx-auto h4">Current Balance: <p className="staked-balance-label text-break h5">loading...</p></div>)}
            <hr className="solid pt-0 mt-0" />
            {state === 'start' && (<div className="balance-font mx-auto pb-3 center-text">Earned To Date: <p className="earned-balance-label text-break h2">loading...</p></div>)}
            {state === 'withdrawA' && (<div className="pt-4 balance-font mx-auto center-text pb-3">Estimated Withdrawl: <p className="estimated-label text-break h2">loading...</p></div>)}
            {state === 'start' && (<Button variant="primary" size="lg" onClick={toWithdrawPage} className="stake-btn">Withdraw</Button>)}
            {state === 'start' && (<Button variant="primary" size="lg" onClick={toStakePage} className="stake-btn dep-btn mb-3">Deposit</Button>)}
            {state === 'withdrawA' && (<Button variant="primary" size="lg" onClick={handleWithdraw} className="confirm-btn mb-3">Confirm Withdrawal</Button>)}
          </Stack>
        </Col>
        <Col xs md="3">
        </Col>
      </Row>
    </Container>
    <Container className="main-footer">
      <p className="accrue-notice pt-2"></p>
    </Container>
  </>;
};

export default WithdrawComponent;
