import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
import CarrTkn from '../assets/img/carrTkn.svg';
import ReactDOM from 'react-dom';
import logo from '../assets/img/logo.svg';
import mMask from '../assets/img/iconMetamask.png';
import '../App.css';
import jQuery from "jquery";
import $ from "jquery";
import StakingComponent from './StakingComponent';
import { getAddr, floor10, getBalance } from '../operations.js';

const WalletComponent = () => {
  const [addr, setAddr] = useState('');
  const formatter = new Intl.NumberFormat('en-US', { style: 'decimal' });

  useEffect(() => {
    jQuery(function () {
      const promises = [getAddr(), getBalance()];
        
      return Promise.all(promises).then(function(res) {
        $(".main-addr-label").text(res[0]);
        $(".addr-label").text(res[0]);
        $(".total-btn").text(formatter.format(floor10(res[1]), -6));
        setAddr(res[0]);
        return true;
      }); 
    })
  });

  function home() {
    ReactDOM.render(<StakingComponent/>, document.getElementById('root'));
  }

  return <>
  <Container>
    <Row className="justify-content-md-center mt-5">
      <Col xs="3" md="2">
        <Image className="justify-content-center main-logo" onClick={home} src={logo} />
      </Col>
      <Col xs="1" md="1">
        {/* <p>Ropsten</p> */}
      </Col>
      <Col xs="4" md="1">
        <span className="rounded-pill btn btn-sm total-btn active" role="button" aria-pressed="true"></span>
      </Col>
      <Col xs="4" md="2">
        <Row>
          <Col xs="7">
            <p className="addr-label"></p>
          </Col>
          <Col xs="4">
            <Image className="mMask" src={mMask}/>
          </Col>
          <Col xs="1">
          </Col>
        </Row>
      </Col>
    </Row>
</Container>
<Container className="main">
    <Row className="justify-content-md-center">
      <Col xs md="3">
      </Col>
      <Col md="6" className="play">
        <Stack gap={1} className="col-md-5 mx-auto pt-5">
            <Image className="pt-2 justify-content-center center-icon mx-auto" src={CarrTkn} />
            <p className="pt-4 mx-auto main-addr-label">loading...</p>
            <p className="pt-1 mx-auto wallet-txt">Connected With Metamask</p>
            <hr className="solid pt-0 mt-0" />
            <a href={`https://polygonscan.com/address/${addr}`}
            className="scan-btn btn-light rounded-pill btn mx-auto mb-3"
            role="button">View on Polygonscan</a>
        </Stack>
      </Col>
      <Col xs md="3">
      </Col>
    </Row>
  </Container></>;
}

export default WalletComponent;
