import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import Logo from '../assets/img/full_logo.svg';
import StakingComponent from './StakingComponent';
import WithdrawComponent from './WithdrawComponent';
import '../App.css';
import jQuery from "jquery";
import { detectProvider, getStakedBalance } from '../operations.js';

const SplashConnect = props => {
  const [stakeVar, setStake] = useState(false);

  useEffect(() => {
    jQuery(function () {
      const promises = [getStakedBalance()];

      return Promise.all(promises).then(function(res) {
        if (res[0] > 0) {
          setStake(true);
        }
      }).catch((error) => {
        console.error(error);
      }); 
    })
  });

  function learnToStake() {
    window.open('https://carnomaly.io/carrstaking/', '_blank');
  }

  function handleConnect() {
    try {

      detectProvider().then((provider) => {
        console.log(provider.isMetaMask);
        if (provider) {
          if(stakeVar) {
            ReactDOM.render(<WithdrawComponent />, document.getElementById('root'));
          } else {
            ReactDOM.render(<StakingComponent />, document.getElementById('root'));
          }
        }
      }).catch((error) => {
        console.error(error);
      });
    } catch (err) {
      console.log(err);
      alert('Please install MetaMask or login');
    }
  }

  return <>
    <Container className="main-splash">
      <Row className="justify-content-md-center mt-5">
        <Col xs="1" md="4">
        </Col>
        <Col xs="10" md="4">
          <Stack gap={1} className="mx-auto pt-2">
          <Image className="justify-content-center mx-auto splash-logo pt-2" src={Logo} />
          <h1 className="justify-content-center splash-text mx-auto pt-3 pb-3">Connect Your Wallet to start earning Staking Rewards.</h1>
          <Button variant="primary" size="lg" onClick={handleConnect} className="connect-btn">Connect to a Wallet</Button>
          <Button variant="primary" size="lg" onClick={learnToStake} className="stake-btn dep-btn">Learn How to Stake</Button>
          <p className="splash-warn pt-2">IMPORTANT: You must stake your funds from metamask. If you stake your funds directly from an exchange (i.e.: Probit or KuCoin), you will lose those funds and they will not be recoverable.</p>
          <p className="splash-warn">DISCLAIMER: At this time you must use the chrome desktop browser with the Metamask extension or Metamask mobile browser to stake your tokens.</p>
        </Stack>
        </Col>
        <Col xs="1" md="4">
        </Col>
      </Row>
      </Container>
      </>;
}

export default SplashConnect;
