import SplashConnect from './views/SplashConnect';
import './App.css';
import React, { useState } from 'react';

function App() {
  const [setConnected] = useState(false);

  return (
    <div>
      <SplashConnect updateConected={() => setConnected(true)} />
    </div>
  );
}

export default App;
