import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
import ReactDOM from 'react-dom';
import WalletComponent from './WalletComponent';
import CarrTkn from '../assets/img/carrTkn.svg';
import logo from '../assets/img/logo.svg';
import mMask from '../assets/img/iconMetamask.png';
import '../App.css';
import jQuery from "jquery";
import $ from "jquery";
import { getStakedBalance, getAddr, transfer, floor10, getBalance } from '../operations.js';
import WithdrawComponent from './WithdrawComponent';

const StakingComponent = () => {
  const [Input, setInput] = useState('');
  const formatter = new Intl.NumberFormat('en-US', { style: 'decimal' });

  useEffect(() => {
    jQuery(function () {
        const promises = [getBalance(), getAddr(), getStakedBalance()];
        
        return Promise.all(promises).then(function(res){
          $(".total-btn").text(formatter.format(floor10(res[0]), -6));
          $(".addr-label").text(res[1]);
          $(".balance-label").text(formatter.format(floor10(res[0], -6)));
          return true;
        })
    })
  });

  function toWalletPage() {
    ReactDOM.render(<WalletComponent />, document.getElementById('root'));
  }

  function toWithdrawPage() {
    ReactDOM.render(<WithdrawComponent />, document.getElementById('root'));
  }

  function home() {
    ReactDOM.render(<StakingComponent/>, document.getElementById('root'));
  }

  function handleChange(e) {
    const regex = /^[0-9\b]+$/;
    // if value is not blank, then test the regex
    if (e.target.value === '' || regex.test(e.target.value)) {
      setInput(e.target.value);
    }
  }

  function handleStake() {
    if (Input > 0) {
      $('.stake-btn').text('Waiting...');
      $('.stake-btn').prop("disabled", true);
      $('.dep-btn').prop("disabled", true);
      $(document.body).css("background-color", "#D3D3D3");
      transfer(Input).then((res) => {
        if (res) {
          $(document.body).css({ background: "linear-gradient(to bottom, rgba(242,149,30,0), rgba(255,174,0,0.2))" });
          toWithdrawPage();
        } else {
          $('.stake-btn').prop("disabled", false);
          $('.dep-btn').prop("disabled", false);
          $('.stake-btn').text('Stake');
          $(document.body).css({ background: "linear-gradient(to bottom, rgba(242,149,30,0), rgba(255,174,0,0.2))" });
        }
      })
    }
  }

  return <>
  <Container>
    <Row className="justify-content-md-center mt-5">
      <Col xs="3" md="2">
        <Image className="justify-content-center main-logo" onClick={home} src={logo} />
      </Col>
      <Col xs="1" md="1">
        {/* <p>Ropsten</p> */}
      </Col>
      <Col xs="4" md="1">
        <span className="rounded-pill btn btn-sm total-btn active" role="button" aria-pressed="true"></span>
      </Col>
      <Col xs="4" md="2">
        <Row>
          <Col xs="7">
            <p onClick={toWalletPage} className="addr-label"></p>
          </Col>
          <Col xs="4">
            <Image className="mMask" src={mMask} onClick={toWalletPage} />
          </Col>
          <Col xs="1">
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
  <Container className="main">
      <Row className="justify-content-md-center">
        <Col xs md="3">
        </Col>
        <Col md="6" className="play">
          <Stack gap={1} className="col-md-5 mx-auto pt-5">
            <Image className="justify-content-center center-icon mx-auto" src={CarrTkn} />
            <div className="pt-4 pb-3 balance-font mx-auto center-text">Balance: <span className="balance-label">loading...</span></div>
            <hr className="solid pt-0 mt-0" />
            <InputGroup className="mb-3">
              <FormControl
                onChange={handleChange}
                value={Input}
                maxLength="15"
                className="stake-input"
                placeholder="Enter CARR amount"
                aria-label="Default"
                aria-describedby="inputGroup-sizing-default" />
            </InputGroup>
            <Button variant="primary" size="lg" onClick={handleStake} className="mt-4 stake-btn">Stake</Button>
            <Button variant="primary" size="lg" onClick={toWithdrawPage} className="form-btn dep-btn mb-3">Withdraw</Button>
          </Stack>
        </Col>
        <Col xs md="3">
        </Col>
      </Row>
    </Container><Container className="main-footer">
      <p className="splash-warn pt-2">IMPORTANT: You must stake your funds from metamask. If you stake your funds directly from an exchange (i.e.: Probit or KuCoin), you will lose those funds and they will not be recoverable.</p>
    </Container></>
}

export default StakingComponent;
